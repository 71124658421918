<template>
  <Layout :title="pageTitle">
    <Styles
      :items="styleList"
      :loadMore="loadMore"
      @on-click="chooseStyle"
      @on-change="changeStyle"
    />
  </Layout>
</template>

<script>
import Layout from '@/components/Layout'
import Styles from '@/components/Styles'
import { getStyles } from '../api/create'

export default {
  name: 'Style',
  components: {
    Layout,
    Styles
  },
  data() {
    return {
      styleList: [],
      isChange: false
    }
  },
  computed: {
    pageTitle() {
      return this.style === 'aiWord'
        ? this.$t('style.wordTitle')
        : this.$t('style.songTitle')
    },
    style() {
      // aiWord作词 aiSong作曲
      return this.$route.query.type || 'aiWord'
    },
    // styleList() {
    //   return this.isChange
    //     ? this.$t('styleMap')[this.style].slice(5)
    //     : this.$t('styleMap')[this.style].slice(0, 5)
    // },
    loadMore() {
      return this.$t('styleMap')[this.style].length > 5
    }
  },
  created() {
    console.log('created')
  },
  mounted() {
    if (!/source=PLATFORM/.test(location.href)) {
      this.fetchStyles()
      // let token = sessionStorage.getItem('tk')
      // if (token) {
      //   this.fetchStyles()
      // }
    }

    window.addEventListener(
      'message',
      event => {
        if (event.data?.msg) {
          console.log('token >>>>> ', event.data?.msg)
          window.sessionStorage.setItem('tk', event.data?.msg)
          this.fetchStyles()
        }
      },
      false
    )
  },
  methods: {
    chooseStyle(obj) {
      console.log(obj)
      // if (this.style === 'aiWord') {
      this.$router.push({
        name: 'create',
        query: { id: obj.val, style: 'rock', title: obj.name }
      })
      // } else if (this.style === 'aiSong') {
      //   this.$router.push({
      //     name: 'compose',
      //     query: { style: obj.val, title: this.styleList[obj.index].title }
      //   })
      // }
    },
    changeStyle() {
      this.isChange = !this.isChange
    },
    fetchStyles() {
      getStyles().then(res => {
        if (res.code == 200) {
          this.styleList = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" module></style>
